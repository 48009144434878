<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Acciones
      </h5>
      <b-badge variant="light-primary">
        ADMIN
      </b-badge>
      <small class="text-muted w-100">{{ (new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ") }}</small>
    </b-card-header>

    <b-card-body>
      <!-- <ul class="list-unstyled my-1"> -->
      <!-- <li>
          <span class="align-middle">5 Users</span>
        </li> -->
      <!-- <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
     </li> -->
      <!-- </ul> -->

      <v-select
        v-model="status"

        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="opcionesStatus"

        @input="(val) => changeProspectStatus(val)"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-1"
        variant="danger"

        @click="toggleUserStatus(1,2)"
      >
        Cambiar Statuss
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        class="mt-1"
        @click="createRayo(1,2)"
      >
        Crear en Thor Fleet Manager
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {

  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  data() {
    return {
      status: '',
      opcionesStatus: [{ value: 1, label: 'En Revisión' }, { value: 2, label: 'Creado en Fleet Manager' }],
    }
  },

  methods: {
    toggleUserStatus(statusIn) {
      console.log(statusIn, this.$route.params.id)
      store.dispatch('app-user/toggleUserStatus', { status: statusIn, id: this.$route.params.id })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    },
    changeProspectStatus() {
      console.log(this.status.value, ':', this.$route.params.id)

      store.dispatch('app-user/changeProspectStatus', { status: this.status, id: this.$route.params.id })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
