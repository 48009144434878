<template>
  <b-card

    title="Formulario de Registros"
  >
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-1"
      variant="primary"

      @click="crearPartners(userData.documents.responses.answers)"
    >
      Crear en Partners
    </b-button> -->
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        class="mt-4"
        variant="info"
        title="Se ha relizado un nuevo registro en la plataforma"

        subtitle="Detalles de las respuestass en formulario de registro"
      />
      <!-- icon="CameraIcon" -->
      <app-timeline-item
        v-for="(data,index) in userData.documents.responses.answers"
        :key="index"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <!-- <h6>{{ getDefinition(data.field.id).title }}  </h6> -->
          <h6>¿QUÉ ES ESTO? </h6>
          <small class="text-muted">{{ userData.documents.ts }}</small>
        </div>
        <p />
        Tipo: {{ data.type }}
        <p
          v-if="data.type=='file_url'"
          class="mb-0"
        >

          <expandable-image

            :src="data.file_url"

            class="
            expan
            mr-1"
          />

          <!-- <b-img
            :src="data.file_url "
            height="auto"
            width="100"
            class="mr-1"
          /> -->
          <span class="align-bottom">Imagen subida</span>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-lg
            :user-data="data.file_url"
            variant="outline-primary"
          >
            Large Modal
          </b-button> -->
          <!-- <b-modal
            id="modal-lg"

            centered
            size="lg"
            title="Large Modal"
          >
            <b-card-text>Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly.</b-card-text>
          </b-modal> -->

        </p>
        <p
          v-else-if="data.type=='text'"
          class="mb-0"
        >
          Respuesta: {{ data.text }}

        </p>
        <p
          v-else-if="data.type=='email'"
          class="mb-0"
        >
          Respuesta: {{ data.email }}

        </p>
        <p
          v-else-if="data.type=='choice'"
          class="mb-0"
        >
          Selección: {{ data.choice.label }}

        </p>
        <p
          v-else-if="data.type=='phone_number'"
          class="mb-0"
        >
          Respuesta: {{ data.phone_number }}

        </p>
        <p
          v-else-if="data.type=='number'"
          class="mb-0"
        >
          Respuesta: {{ data.number }}

        </p>

        <p
          v-else-if="data.type=='choices'"
          class="mb-0"
        >
          <span
            v-for="(i) in data.choices.labels"
            :key="i"
          >
            Selección: {{ i }} <br>
          </span>

        </p>
      </app-timeline-item>

      <!-- <app-timeline-item icon="DollarSignIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>12 Invoices have been paid</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Invoices have been paid to the company.</p>
        <p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item> -->

      <!-- <app-timeline-item variant="warning">

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Client Meeting</h6>
          <small class="text-muted">45 min ago</small>
        </div>
        <p>Project meeting with john @10:15am.</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
          </template>
          <h6>John Doe (Client)</h6>
          <p class="mb-0">
            CEO of Infibeam
          </p>
        </b-media>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        title="Create ass new project for client"
        subtitle="Add files to new design folder"
        time="2 days ago"
      /> -->
    </app-timeline>
  </b-card>
</template>

<script>
// import store from '@/store'
import {
  BCard,
  // BImg, BMedia, BAvatar,BButton,
  VBModal,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
// import userStoreModule from '../userStoreModule'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    // BButton,
    // BModal,
    BCard,
    // BImg,
    // BMedia,
    // BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // swiperData: [
      //   { img: this.userData.responses },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      // ],
      imagesFromDocs: [],
    }
  },
  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'postulaciones'
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  // },
  mounted() {
    console.log(this.userData.image_rayo)

    this.imagesFromDocs = this.userData.documents.responses.answers.filter(
      answer =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (answer.type === 'file_url'
        ),
    )
    console.log(this.imagesFromDocs)
  },
  methods: {
    // crearPartners(dataIn) {
    //   console.log(dataIn)
    //   store.dispatch('postulaciones/createEpaPartnersServer', dataIn)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         console.log(false)
    //       }
    //     })

    //   return {
    //     a: false,
    //   }
    // },
    // getDefinition(id) {
    //   console.log(id)
    //   return this.userData.documents.responses.definition.fields.find(x => x.id === id)
    // },
  },
}
</script>

<style>
.expan {
  width: 140px;
  max-width: 100%;
}

</style>
