/* eslint-disable prefer-template */
import axios from '@axios'
import store from '@/store'

// console.log(axios)
// console.log(store.state.appConfig)
// import { email } from 'vee-validate/dist/rules'

// const apiUrl = 'https://us-central1-entregasrayo.cloudfunctions.net/serverPartners'
// const apiUrl = 'http://localhost:8080'
// console.log(store.state.appConfig.layout.apiUrl)
// eslint-disable-next-line prefer-destructuring
const apiUrl = store.state.appConfig.layout.apiUrl
// console.log(apiUrl)
// console.log('modulo ok')
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchUsers(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/user/users', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    test() {
      // console.log('test ok')
      return true
    },
    // createEpaPartnersServer(dataIn) {
    //   console.log(dataIn.data)
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(apiUrl + '/createEpaPartnersServer', dataIn)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiUrl + '/getPostulaciones', { params: queryParams })
          // .get('http://localhost:8080/getPostulaciones', { params: queryParams })
          .then(response => resolve(response))
        // console.log(2)
          .catch(error => reject(error))
      })
    },
    toggleUserStatus(a, params) {
      // console.log(params.id)
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + '/toggleUserStatus', { id: params.id, status: params.status })
          // .post('http://localhost:8080/toggleUserStatus', { id: params.id, status: params.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRayo(a, params) {
      // console.log(params.id)
      return new Promise((resolve, reject) => {
        axios
          // .post('http://localhost:8080/toggleUserStatus', { id: params.id, status: params.status })
          .post(apiUrl + '/toggleUserStatus', { id: params.id, status: params.status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPostulaciones(ctx, { id }) {
      // console.log(id)
      return new Promise((resolve, reject) => {
        axios.get(apiUrl + `/getDocuments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomIdField(rareza, dataIn) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiUrl + '/updateCustomIdField', { user: dataIn.id, email: dataIn.email })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
